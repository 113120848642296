import React, { useRef, useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { AiOutlineMenu } from 'react-icons/ai'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Section from '../layout/Section'
import Button from '../ui/Button'
import Modal from '../components/Modal'
import { Context as AuthContext } from '../context/authContext'
import { Context as DashboardContext } from '../context/dashboardContext'
import { Link, NavLink } from 'react-router-dom'
import useOutsideClickHandler from '../hooks/useClickOutside'
import { logout } from '../_services/user'
import { constant } from '../_config/constant'
import { processMediaUrl } from '../_helper/utils'

function HeaderChild() {
    const history = useHistory()
    const { user, fetchUser } = useContext(AuthContext)
    const { toggleDashboard } = useContext(DashboardContext)
    const mobileNavRef = useRef()
    const [showMobileSidebar, setMobileSidebar] = useState(false)

    useOutsideClickHandler(mobileNavRef, () => {
        setMobileSidebar(false)
    })

    const activeLinkClassname = ''
    const headerLinkClassname = `z-100 block cursor-pointer  text-gray-600 text-sm truncate hidden md:block`

    useEffect(() => {
        if (!user.authStatus && localStorage.getItem(constant.TOKEN_KEY)) {
            fetchUser()
        }
    }, [])

    return (
        <Section className=" relative">
            <header className="p-2 flex justify-between items-center">
                <div className="w-full mx-auto flex items-center justify-between ">
                    <div className="flex gap-2 items-center">
                        {history.location.pathname.startsWith('/dashboard') && (
                            <span onClick={toggleDashboard} className="py-2 px-2 hover:bg-gray-200 rounded-full md:hidden place-content-center">
                                <BsThreeDotsVertical size={24} />
                            </span>
                        )}

                        <Link to="/">
                            {/* <Link to="/home"> */}
                            <img src="/assets/resources/logo-text.png" alt="Live Style logo" className="h-10 lg:h-14 mr-10" />
                        </Link>
                    </div>
                    <div className=" justify-around gap-5 items-center text-gray-500 hidden md:flex md:mr-0 mr-20">
                        <NavLink
                            to="/"
                            // to="/home"
                            activeClassName={activeLinkClassname}
                            className={headerLinkClassname}
                        >
                            Home
                        </NavLink>
                        {/* <NavLink
              to="/about"
              activeClassName={activeLinkClassname}
              className={headerLinkClassname}
            >
              About Us
            </NavLink> */}
                        <NavLink to="/property-listing?bookingType=Cold+bed" activeClassName={activeLinkClassname} className={headerLinkClassname}>
                            Search Property
                        </NavLink>
                        <NavLink to="/become-host" activeClassName={activeLinkClassname} className={headerLinkClassname}>
                            Become a Host
                        </NavLink>
                        <NavLink to="/contact" activeClassName={activeLinkClassname} className={`${headerLinkClassname} `}>
                            Contact
                        </NavLink>
                        <NavLink to="/faq" activeClassName={activeLinkClassname} className={headerLinkClassname}>
                            FAQ
                        </NavLink>
                        {!localStorage.getItem(constant.TOKEN_KEY) ? (
                            <>
                                <NavLink to="/login" className="">
                                    <Button className=" z-100 cursor-pointer text-primary-blue border-primary-blue border rounded-md text-[11px] leading-5 md:text-[14px]">
                                        Login
                                    </Button>
                                </NavLink>
                                <NavLink to="/register" className="">
                                    <Button btnType="filled" className=" z-100 cursor-pointer text-[11px] border-primary-blue border leading-5 md:text-[14px]">
                                        Register
                                    </Button>
                                </NavLink>
                            </>
                        ) : (
                            <>
                                <Link to="/dashboard">
                                    <img
                                        onError={function ({ currentTarget }) {
                                            currentTarget.src = '/user.png'
                                        }}
                                        className="h-8 w-8  my-auto right-0 origin-center  rounded-md object-cover"
                                        src={user.authStatus ? processMediaUrl(user.avatar) : '/user.png'}
                                        alt="dev"
                                    />
                                </Link>
                                <Button onClick={logout} btnType="filled" className=" z-100 cursor-pointer">
                                    Logout
                                </Button>
                            </>
                        )}
                    </div>
                    <div className="md:hidden gap-4 pr-2.5 flex items-center justify-end">
                        {!user.authStatus ? (
                            <div className='flex items-center gap-1.5'>
                                <NavLink to="/login" className="">
                                    <Button  className=" cursor-pointer text-primary-blue border-primary-blue border text-[11px] leading-5 md:text-[14px]">
                                        Login
                                    </Button>
                                </NavLink>

                                <NavLink to="/register" className="">
                                    <Button btnType="filled" className="cursor-pointer border border-primary-blue h-auto text-[11px] leading-5 md:text-[14px]">
                                        Register
                                    </Button>
                                </NavLink>
                            </div>
                        ) : (
                            <>
                                <Link to="/dashboard">
                                    <img
                                        onError={function ({ currentTarget }) {
                                            currentTarget.src = '/user.png'
                                        }}
                                        className="h-9 w-9  my-auto  right-0 origin-center  rounded-md object-cover"
                                        src={user.authStatus ? processMediaUrl(user.avatar) : '/user.png'}
                                        alt="dev"
                                    />
                                </Link>
                                <Button onClick={logout} btnType="filled" className=" z-100 cursor-pointer">
                                    Logout
                                </Button>
                            </>
                        )}
                       <AiOutlineMenu onClick={() => setMobileSidebar(!showMobileSidebar)}  size={28} className="text-primary" />
                    </div>
                </div>
            </header>
            {showMobileSidebar && (
                <Modal>
                    <div
                        ref={mobileNavRef}
                        className="animate__slideInLeft animate__animated  animate__faster min-h-screen bg-white p-4 absolute left-0 top-0 bottom-0 w-[15rem] flex flex-col text-primary-blue gap-4"
                    >
                        <Link to="/">Home</Link>
                        {/* <Link to="/home">Home</Link> */}
                        {/* <Link to="/about">About Us</Link> */}
                        <Link to="/property-listing">Search Property</Link>
                        <Link to="/dashboard">Dashboard</Link>
                        <Link to="/become-host">Become a Host</Link>
                        <Link to="/contact">Contact</Link>
                        <Link to="/faq">FAQ</Link>
                        {!user.authStatus ? (
                            <Link to="/login">Login</Link>
                        ) : (
                            <Link to="#" onClick={logout}>
                                Logout
                            </Link>
                        )}
                    </div>
                </Modal>
            )}
        </Section>
    )
}

// country code

function Header({ type }) {
    switch (type) {
        case 'fixed': {
            return (
                <div>
                    <HeaderChild />
                </div>
            )
        }
        default: {
            return (
                <div className="bg-white  z-100 fixed top-0 left-0 right-0">
                    <HeaderChild />
                </div>
            )
        }
    }
}

export default Header
