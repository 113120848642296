import React, { useEffect, useState, Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import useTextZoomDisable from './hooks/useTextZoomDisable'
import ScrollToTop from './utils/ScrollToTop'
import { ToastContainer } from 'react-toastify'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { AppConfig } from './Config'
import MaintenanceMode from './components/MaintenanceMode'
import { siteSettings } from './_services/general'
import PrivateRoute, { VerificationRoute } from './utils/PrivateRoute'


import 'animate.css'
import 'react-tippy/dist/tippy.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

// full page loader
import FullPageLoader from './components/FullPageLoader'
import CookiePolicy from './pages/CookiePolicy'
import Disclaimer from './pages/Disclaimer'
import AcceptableUsePolicy from './pages/AcceptableUsePolicy'

const stripePromise = loadStripe(AppConfig.stripePublisherKey)

// Lazy loading components
const BecomeHost = lazy(() => import('./pages/Become-host'))
const PropertyListing = lazy(() => import('./pages/Property-listing'))
const About = lazy(() => import('./pages/About'))
const Contact = lazy(() => import('./pages/Contact'))
const Test = lazy(() => import('./pages/test'))
const Register = lazy(() => import('./pages/Register'))
const Login = lazy(() => import('./pages/Login'))
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'))
const ResetPasswordEmail = lazy(() => import('./pages/ResetPasswordEmail'))
const ResetPasswordPhone = lazy(() => import('./pages/ResetPasswordPhone'))
const Dashboard = lazy(() => import('./pages/dashboard'))
const PropertyDetails = lazy(() => import('./pages/Property-details'))
const Booking = lazy(() => import('./pages/Booking'))
const Faqs = lazy(() => import('./pages/Faqs'))
const PaymentConfirmation = lazy(() => import('./pages/payment-confirmation'))
const BookingDetails = lazy(() => import('./pages/banking-details'))
const Verification = lazy(() => import('./pages/Verification'))
const TermsCondition = lazy(() => import('./pages/TermsCondition'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const LandingPage = lazy(() => import('./pages/LandingPage'))

function App() {
    const [settings, setSettings] = useState(null)

    useEffect(() => {
        siteSettings()
            .then((data) => {
                setSettings(data.data.data)
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])

    useTextZoomDisable()

    useEffect(() => {
        window.onunhandledrejection = (err) => {
            // handle error
        }
    }, [])

    if (settings && settings.maintenanceMode) {
        return <MaintenanceMode />
    }

    return (
        <Elements stripe={stripePromise}>
            <div className="App">
                <ScrollToTop />
                <ToastContainer />
                <Suspense fallback={<FullPageLoader />}>
                    <Switch>
                        <Route exact path="/" component={LandingPage} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/home" component={LandingPage} />
                        <Route exact path="/property-listing" component={PropertyListing} />
                        <Route exact path="/property-details/:id" component={PropertyDetails} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/booking-details" component={BookingDetails} />
                        <Route exact path="/payment-confirmation" component={PaymentConfirmation} />
                        <Route exact path="/booking-preview" component={Booking} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <Route exact path="/test" component={Test} />
                        <Route exact path="/faq" component={Faqs} />
                        <Route exact path="/become-host" component={BecomeHost} />
                        <Route exact path="/register" component={Register} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/forget-password" component={ForgetPassword} />
                        <Route exact path="/reset-password-email" component={ResetPasswordEmail} />
                        <Route exact path="/reset-password-phone" component={ResetPasswordPhone} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/terms-and-condition" component={TermsCondition} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/cookie-policy" component={CookiePolicy} />
                        <Route exact path="/disclaimer" component={Disclaimer} />
                        <Route exact path="/acceptable-use-policy" component={AcceptableUsePolicy} />
                        <VerificationRoute exact path="/verification" component={Verification} />
                    </Switch>
                </Suspense>
            </div>
        </Elements>
    )
}

export default App
