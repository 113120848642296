import React, { useEffect } from "react";
import Header from "../front/Header";
import Footer from "../front/Footer";
import Section from "../layout/Section";

export default function CookiePolicy() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
    document.title='Cookie Policy | CrewmatesApp'
  }, []);

  return (
    <div>
      <Header />
      <Section className="my-20 font-roboto ">
      
        <div className="text-primary-blue-dark  mx-4 md:w-8/12 md:mx-auto text-sm lg:text-base">
        <div name="termly-embed" data-id="452a7476-704a-41a9-a232-a922d160b806"></div>
        </div>
      </Section>
      <Footer />
    </div>
  );
}

