import React, { useEffect } from "react";
import Header from "../front/Header";
import Footer from "../front/Footer";
import Section from "../layout/Section";

function AcceptableUsePolicy() {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
    document.title='Acceptable Use Policy | CrewmatesApp'
  }, []);

  return (
    <div>
      <Header />
      <Section className="my-20 font-roboto ">
      
        <div className="text-primary-blue-dark  mx-4 md:w-8/12 md:mx-auto text-sm lg:text-base">
        <div name="termly-embed" data-id="1a16f869-9287-4a9b-a24b-8119650e4d77"></div>
        
        </div>
      </Section>
      <Footer />
    </div>
  );
}

export default AcceptableUsePolicy;
